<template>
    <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
        <ListingComponent ref="PlaceList"
                          class="bg-white"
                          :addNewButton="addNewButton"
                          :requestProvider="requestProvider"
                          :columnConfig="columnConfig">
        </ListingComponent>
        <b-modal v-model="isDeleteModal"
                 :no-close-on-backdrop="true"
                 centered title="Delete modal"
                 @cancel="closeDeleteModal"
                 @ok="removeItem">
            <form>
                <h3 class="text-black-50">Do you really want to delete</h3>
            </form>
        </b-modal>
        <b-modal ref="filter-name-modal" v-model="rejectReasonModel" title="Reject reason"
                 @cancel="closeRejectReasonModal"
                 @ok="rejectUserPlace">
            <Textarea required="true" v-model="rejectReason" placeholder="Reason">Reason</Textarea>
        </b-modal>
    </div>
</template>

<script>
  import ListingComponent from '@/components/listing/ListingComponent';
  import moment from 'moment';

  export default {
        name: "PlacesListPage",
        data () {
            return {
                id: null,
                isDeleteModal: false,
                rejectReasonModel: false,
                rejectReason: '',
                columnConfig: [
                  {
                    columnCode: 'action',
                    columnName: 'Action',
                    value: (item) => {
                      return (
                          <b-dropdown variant="link" class="listing-action">
                          <b-dropdown-item href={'#/places/edit/' + item.id}>Edit</b-dropdown-item>
                          <b-dropdown-item onClick={() => this.changeStatus(item.id,'APPROVED')}>Approve</b-dropdown-item>
                          <b-dropdown-item onClick={() => this.showRejectReasonModal(item.id)}>Reject</b-dropdown-item>
                          <b-dropdown-item onClick={() => this.showDeleteModal(item)}>Delete</b-dropdown-item>
                      </b-dropdown>
                    )
                    }
                  },
                  {
                    columnCode: 'LOGO',
                    columnName: 'Logo',
                    customizeColumn: true,
                    value: item => {
                      if (!item.logo) return ''
                      return <img className="profile-img-sm rounded-circle mb-2" style="height: 40px;" src={item.logo.imageURL}/>
                    }
                  },
                  {
                        columnCode: 'NAME',
                        columnName: 'Name',
                        link: '/places/edit',
                        align: 'left',
                        value: item => {
                            return item.name
                        }
                    },
                     {
                        columnCode: 'CATEGORY',
                        columnName: 'Category',
                        align: 'left',
                        value: item => {
                            return item.categoryList?item.categoryList[0].name:''
                        }
                    },
                     {
                        columnCode: 'STATUS',
                        columnName: 'Status',
                        align: 'left',
                        value: item => {
                          return item.status;
                        }
                    },
                     {
                        columnCode: 'PHONE',
                        columnName: 'Phone',
                        align: 'left',
                        value: item => {
                          return item.phone;
                        }
                    },
                     {
                        columnCode: 'CITY',
                        columnName: 'City',
                        align: 'left',
                        value: item => {
                          return item.address && item.address.cityName ? item.address.cityName : '';
                        }
                    },
                     {
                        columnCode: 'COUNTRY',
                        columnName: 'Country',
                        align: 'left',
                        value: item => {
                          return item.address && item.address.countryName ? item.address.countryName : '';
                        }
                    },
                     {
                        columnCode: 'CREATED_DATE',
                        columnName: 'Created date',
                        align: 'left',
                        value: item => {
                          return item.createdDate ? moment(item.createdDate).format('MM/DD/YYYY hh:mm') : '';
                        }
                    },
                     {
                        columnCode: 'UPDATED_DATE',
                        columnName: 'Updated date',
                        align: 'left',
                        value: item => {
                          return item.updatedDate ? moment(item.updatedDate).format('MM/DD/YYYY hh:mm') : '';
                        }
                    }
                ]
            }
        },
        methods: {
            requestProvider (payload) {
                return this.axios.get('public/place/list', payload)
            },
            addNewButton () {
                return (
                    <router-link class="btn btn-success mr-3" to="/places/add"><i class="fas fa-plus-circle"></i></router-link>


            )
            },
            closeDeleteModal () {
                this.id = null
                this.$nextTick(() => {
                    this.isDeleteModal = false
                })
            },
            showRejectReasonModal (placeId) {
                this.id = placeId
                this.rejectReasonModel = true
            },
            closeRejectReasonModal () {
                this.id = null
                this.rejectReason = null
                this.$nextTick(() => {
                  this.rejectReasonModel = false
                })
            },
            rejectUserPlace () {
              this.changeStatus(this.id,'REJECTED');
            },

            changeStatus (placeId, status) {
            this.axios.post('place/change-status', {id: placeId, status: status, reason: this.rejectReason})
                .then((result) => {
                  this.$refs.PlaceList.doRequest()
                  if (result.data && result.data.status === 'success') {
                    if (status ==='APPROVED') {
                      this.$toastr.success('Place Approved deleted');
                    } else {
                      this.$toastr.success('Place Rejected');
                    }
                  }
                })
            },
            showDeleteModal (item) {
                if (!item.id) {
                    this.$toastr.error('Item not found')
                }
                this.id = item.id
                this.isDeleteModal = true
            },
            removeItem () {
                if (!this.id) {
                    return
                }
                this.axios.delete('place/item/', {params: {id: this.id}})
                    .then((result) => {
                        this.$refs.PlaceList.doRequest()
                        if (result.data && result.data.status === 'success') {
                            this.$toastr.success('successfully deleted')
                        }
                        this.isDeleteModal = false
                    })
            }
        },
        components: {
            ListingComponent
        }
    }
</script>

<style scoped>

</style>